import React from "react";
import { graphql } from "gatsby";
import resolveImage from "@dataResolvers/resolveImage";
import PageTemplate from "@pageTemplates/HomePage";
import resolvePageBuilder from "@dataResolvers/resolvePageBuilder";
import { useAppState } from "@state";
import QueryContainer, { HeadTemplate } from "../QueryContainer";

export const query = graphql`
  query HomeQuery {
    craft {
      entry(section: "homepage") {
        title
        ... on Craft_homePage_homePage_Entry {
          enableAbTest

          # Hero
          heroHeading: heading0
          heroDescriptor: descriptor1
          heroTerms: highlightedTerms {
            ... on Craft_highlightedTerms_TableRow {
              term
            }
          }
          heroButtons: linkList {
            ... on Craft_linkList_link_BlockType {
              button: linkObject {
                text
                url
              }
            }
          }
          pageBuilder {
            ...PageBuilderFragment
          }
          # Meta
          slug
          url
          metaTitle
          metaDescription
          metaImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
        }
      }
      # b: entry(section: "homepage") {
      #   title
      #   ... on Craft_homePage_homePage_Entry {
      #     # Hero
      #     heroHeading: heading0
      #     heroDescriptor: descriptor1
      #     heroTerms: highlightedTerms {
      #       ... on Craft_highlightedTerms_TableRow {
      #         term
      #       }
      #     }
      #     heroButtons: linkList {
      #       ... on Craft_linkList_link_BlockType {
      #         button: linkObject {
      #           text
      #           url
      #         }
      #       }
      #     }
      #     pageBuilder {
      #       ...PageBuilderFragment
      #     }
      #     # Meta
      #     slug
      #     url
      #     metaTitle
      #     metaDescription
      #     metaImage {
      #       width
      #       height
      #       hasFocalPoint
      #       slug
      #       status
      #       title
      #       focalPoint
      #       url
      #     }
      #   }
      # }
    }
  }
`;

const dataResolver = ({ entry, b }, test) => {
  const {
    // Hero
    heroHeading,
    heroButtons,
    heroDescriptor,
    heroTerms,
    // pb
    pageBuilder,
    // Meta
    url,
    title,
    metaTitle,
    metaDescription,
    metaImage,
    slug,
  } = test && entry.enableAbTest ? b : entry;

  return {
    hero: {
      heading: heroHeading,
      buttons: heroButtons,
      descriptor: heroDescriptor,
      terms: heroTerms.map(({ term }) => term),
    },
    pageBuilder: resolvePageBuilder(pageBuilder),
    meta: {
      url,
      title,
      metaPageTitle: metaTitle,
      metaDescription,
      metaFeaturedImage: resolveImage(metaImage),
      slug,
    },
  };
};

export const Head = ({ data, ...rest }) => {
  const [{ layout }] = useAppState();
  const { test } = layout;
  const { craft } = data;
  const resolvedData = dataResolver(craft, test);
  const eagerUrls = [];
  return <HeadTemplate {...rest} data={resolvedData} eagerUrls={eagerUrls} />;
};

const Page = props => (
  <QueryContainer
    template={PageTemplate}
    dataResolver={dataResolver}
    queryName="HomePageQuery"
    {...props}
  />
);

export default Page;
