import resolveImage from "@dataResolvers/resolveImage";

const reverseBlocks = [
  "imageLeadCopy",
  "imageCopy",
  "imageAccordion",
  "singleTestimonial",
  "caseStudyCarousel",
];

const resolvePageBuilder = blocks => {
  const resolvedblocks = [];
  let reverse = false;
  let reverseGrid = false;
  let prevBlock = null;
  blocks.forEach(block => {
    const { type, uid } = block;
    switch (type) {
      case "imageLeadCopy":
        reverse = !reverse;
        resolvedblocks.push({
          type,
          uid,
          copy: block.copy,
          image: resolveImage(block.image),
          link: block.button,
          reverse,
        });
        break;
      case "imageCopy":
        reverse = !reverse;
        resolvedblocks.push({
          type,
          uid,
          copy: block.copy,
          image: resolveImage(block.image),
          button: block.button,
          secondaryLink: block.secondaryLink,
          reverse,
          color: block.color,
          shape: block.imageStyle,
          textSize: block.bodyCopySize,
        });
        break;
      case "sectionHeading":
        resolvedblocks.push({
          type,
          uid,
          heading: block.heading,
          descriptor: block.descriptor,
          button: block.button,
          variant: "lg",
        });
        break;
      case "newsletterDonateForms":
        resolvedblocks.push({
          type,
          uid,
          newsletter: {
            heading: block.newsletterHeading,
            description: block.newsletterDescriptor,
            formId: block.newsletterFormId,
          },
          donate: {
            heading: block.donateHeading,
            descriptor: block.donateDescriptor,
            url: block.donateFormId,
          },
        });
        break;
      case "organizationCarousel":
        resolvedblocks.push({
          type,
          uid,
          heading: block.heading,
          description: block.descriptor,
          slides: block.organizations?.map(logo => {
            return {
              image: resolveImage(logo?.image0),
              uid: logo?.uid,
            };
          }),
        });
        break;
      case "latestEntries":
        resolvedblocks.push({
          type,
          uid,
          heading: block.heading,
          description: block.description,
          categories: block.categories,
          button: block.viewMoreButton,
          colorScheme: block.colorScheme,
          entries:
            block.entriesOverride?.map(entry => {
              // todo: nuke for future?
              // const typeMap = {
              //   book: "Books",
              //   articleEssay: "Articles/Essays",
              //   academicArticles: "Academic Articles",
              //   video: "Videos",
              //   tool: "Tools",
              // };
              // const categoryLabel =
              //   entry?.topics?.at(0)?.title ||
              //   entry?.articleCategory?.at(0)?.title ||
              //   typeMap[entry.type] ||
              //   `${entry.type
              //     .substring(0, 1)
              //     .toUpperCase()}${entry.type.substring(1)}`;

              const ctaText = {
                events: "Register",
                video: "Watch",
              }[entry?.type];

              return {
                categoryLabel: entry.categoryLabel,
                uid: entry.uid,
                title: entry.title,
                url: entry.url,
                image: resolveImage(entry.image || entry.metaImage),
                ctaText,
              };
            }) || [],
        });
        break;
      case "buttonList":
        resolvedblocks.push({
          type,
          uid,
          heading: block.heading,
          descriptor: block.descriptor,
          links: block.buttons.map(b => ({ ...b.button })),
        });
        break;
      case "cta":
        resolvedblocks.push({
          type,
          uid,
          heading: block.heading,
          descriptor: block.descriptor,
          formId: block.hubspotFormId,
        });
        break;
      case "peopleGrid":
        resolvedblocks.push({
          type,
          uid,
          heading: block.heading,
          people: block.people?.map(person => {
            return {
              uid: person.uid,
              headshot: resolveImage(person.image0),
              name: person.title,
              title: person.heading0,
              affiliation: person.heading1,
              bio: person.copy0,
              hasModal: person.copy0?.length,
            };
          }),
          buttons: block.buttons?.map(button => button.button),
          color: block.color,
        });
        break;
      case "list":
        resolvedblocks.push({
          type,
          uid,
          heading: block.heading,
          list: block.list?.map(item => item.items),
        });
        break;
      case "simpleButtonList":
        resolvedblocks.push({
          type,
          uid,
          buttons: block.buttons?.map(button => button?.button),
        });
        break;
      case "iconGrid":
        resolvedblocks.push({
          type,
          uid,
          columns: block.grid?.map(item => {
            return {
              code: item.icon?.[0].code0,
              heading: item.heading,
              description: item.descriptor,
            };
          }),
        });
        break;
      case "containedAccordion":
        resolvedblocks.push({
          type,
          uid,
          heading: block.heading,
          description: block.descriptor,
          accordions: block.accordions,
        });
        break;
      case "jobOpenings":
        resolvedblocks.push({
          type,
          uid,
          id: "openings",
          blocks: block.openings,
        });
        break;
      case "imageAccordion":
        reverse = !reverse;
        resolvedblocks.push({
          type,
          uid,
          reverse,
          image: resolveImage(block.image),
          color: block.color,
          shape: block.imageStyle,
          heading: block.heading,
          blocks: block.blocks,
        });
        break;
      case "copyAccordion":
        resolvedblocks.push({
          type,
          uid,
          blocks: block.blocks,
        });
        break;
      case "singleTestimonial":
        if (block.testimonial?.[0]) {
          reverse = !reverse;
          resolvedblocks.push({
            type,
            uid,
            quote: block.testimonial?.[0].quote,
            name: block.testimonial?.[0].name,
            position: block.testimonial?.[0].position,
            image: resolveImage(block.testimonial?.[0].image),
            reverse,
          });
        }
        break;
      case "testimonialGrid":
        resolvedblocks.push({
          type,
          uid,
          testimonials: block.testimonials.map(t => ({
            ...t,
            image: resolveImage(t.image),
          })),
        });
        break;
      case "testimonialCarousel":
        resolvedblocks.push({
          type,
          uid,
          slides: block.testimonials.map(t => ({
            ...t?.testimonial?.[0],
            image: resolveImage(t.testimonial[0]?.image),
            bgImage: resolveImage(t.bgImage),
          })),
        });
        break;
      case "statsGrid":
        resolvedblocks.push({
          type,
          uid,
          stats: block.stats,
        });
        break;
      case "typeformEmbed":
        resolvedblocks.push({
          type,
          uid,
          id: block.url?.split("/to/")[1],
          ...block,
        });
        break;
      case "imageWCopyGrid":
        reverseGrid = !reverseGrid;
        resolvedblocks.push({
          type,
          uid,
          color: block.shapesColor,
          cols: 2,
          grid: block.gridItems?.map((item, i) => {
            const roundedCorner = i % 2 === 0 ? "bottomRight" : "topLeft";
            return {
              ...item,
              image: resolveImage(item.image),
              linkList: [item.link],
              roundedCorner: !reverseGrid ? roundedCorner : null,
            };
          }),
        });
        break;
      case "recentCaseStudies":
        resolvedblocks.push({
          type,
          uid,
          entriesOverride: block.entriesOverride?.map(e => ({
            ...e,
            image: resolveImage(e.image),
          })),
        });
        break;
      case "caseStudyCarousel":
        if (reverseBlocks.includes(prevBlock)) {
          // do nothing?
        } else {
          reverse = true;
        }
        resolvedblocks.push({
          type,
          uid,
          reverse,
          heading: block.heading,
          caseStudies: block.caseStudies?.map(e => ({
            ...e,
            image: resolveImage(e.image || e.image0),
          })),
        });
        break;
      case "recentPublications":
        resolvedblocks.push({
          type,
          uid,
          entriesOverride: block.entriesOverride?.map(e => ({
            ...e,
            image: resolveImage(e.image),
          })),
        });
        break;
      case "threeColumnContent":
        resolvedblocks.push({
          type,
          uid,
          col1: {
            heading: block.column1Heading,
            copy: block.column1Copy,
            button: block.column1Button,
          },
          col2: {
            heading: block.column2Heading,
            copy: block.column2Copy,
            button: block.column2Button,
          },
          image: resolveImage(block.column3Image),
        });
        break;
      case "containedIconGrid":
        resolvedblocks.push({
          type,
          uid,
          heading: block.heading,
          descriptor: block.descriptor,
          columns: block.grid?.map(item => {
            return {
              code: item.icon?.[0].code0,
              heading: item.heading,
              description: item.descriptor,
            };
          }),
        });
        break;
      case "smallImageGrid":
        resolvedblocks.push({
          type,
          uid,
          grid: block.grid?.map(item => {
            return {
              uid: item.uid,
              image: resolveImage(item.image),
              heading: item.heading,
              descriptor: item.descriptor,
              link: item.itemLink,
            };
          }),
        });
        break;
      case "copy":
        resolvedblocks.push({
          type,
          uid,
          copy: block.copy,
        });
        break;
      case "hubspotForm":
        resolvedblocks.push({
          type,
          uid,
          heading: block.heading,
          descriptor: block.descriptor,
          formId: block.formId,
        });
        break;
      case "images":
        resolvedblocks.push({
          type,
          uid,
          images: block.images,
          caption: block.caption,
        });
        break;
      case "containedSmallImageGrid":
        resolvedblocks.push({
          type,
          uid,
          heading: block.heading,
          descriptor: block.descriptor,
          grid: block.grid?.map(item => {
            return {
              uid: item.uid,
              image: resolveImage(item.image),
              heading: item.heading,
              descriptor: item.descriptor,
              link: item.itemLink,
            };
          }),
          button: block.button,
        });
        break;
      case "programsOverview":
        resolvedblocks.push({
          type,
          uid,
          programs: block.programs?.map(program => {
            return {
              ...program,
              image: resolveImage(program?.image),
              features: program?.features?.map(f => f?.feature)?.filter(f => f),
            };
          }),
        });
        break;
      case "imageGridWLinks":
        resolvedblocks.push({
          type,
          uid,
          grid: block.imageGridWLinks?.map(item => {
            return {
              ...item,
              image: resolveImage(item?.image),
              linkList: item?.linklist?.map(link => link?.linkListItem),
            };
          }),
        });
        break;
      case "learningTracks":
        resolvedblocks.push({
          type,
          uid,
          tracks: block.tracks?.map(b => ({
            ...b,
            image: resolveImage(b.image),
          })),
          button: block.button,
          link: block.link,
        });
        break;
      case "offeringsTable":
        resolvedblocks.push({
          type,
          uid,
          offerings: block.offerings.map(offering => ({
            ...offering,
            title:
              offering.heading?.replace(/<\/?p[^>]*>/g, "") || offering.title,
          })),
          columnHeadings: block.columnHeadings,
        });
        break;
      case "ctaColored":
        resolvedblocks.push({
          type,
          uid,
          heading: block.heading,
          copy: block.copy,
          button: block.button,
          image: resolveImage(block.image),
        });
        break;
      case "numberedList":
        resolvedblocks.push({
          type,
          uid,
          items: block.items,
          button: block.button,
        });
        break;
      case "overviewGrid":
        resolvedblocks.push({
          type,
          uid,
          heading: block.heading,
          descriptor: block.descriptor,
          items: block.items,
          color: block.color,
        });
        break;
      case "featured":
        resolvedblocks.push({
          type,
          uid,
          heading: block.heading,
          copy: block.copy,
          link: block.link,
          button: block.button,
          image: resolveImage(block.image),
        });
        break;
      case "cardGrid":
        resolvedblocks.push({
          type,
          uid,
          heading: block.heading,
          descriptor: block.descriptor,
          cards: block.cards?.map(c => {
            return {
              ...c,
              link: c.link?.url,
              ctaText: c.link?.text,
              image: resolveImage(c.image),
            };
          }),
        });
        break;
      case "utilityReverse":
        reverse = !reverse;
        break;
      default:
        resolvedblocks.push({ type, uid, ...block });
        break;
    }
    prevBlock = type;
  });

  return resolvedblocks;
};

export default resolvePageBuilder;
