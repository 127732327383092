import React from "react";
import loadable from "@loadable/component";

const PageContainer = loadable(() => import("@layout/DefaultPageContainer"));

const HeroHome = loadable(() => import("@organisms/HeroHome"));
const PageBuilder = loadable(
  () => import("../components/templates/PageBuilder")
);

const HomePage = ({ data, ...rest }) => {
  const { hero, pageBuilder } = data;

  return (
    <PageContainer {...rest} flex={false}>
      <HeroHome {...hero} />
      <PageBuilder blocks={pageBuilder} home />
    </PageContainer>
  );
};

HomePage.defaultProps = {};

export default HomePage;
